export const sliderData = [
    {
        image: "/main-slider/01.jpg",
        heading: "BRATISLAVA 14/10/2023",
        desc: "The Biggest Erasmus Festival en Bratislava con Alex Martini"
    }, 
    {
        image: "/main-slider/02.jpg",
        heading: "BRATISLAVA 14/10/2023",
        desc: "The Biggest Erasmus Festival en Bratislava con Aissa"
    }, 
    {
        image: "/main-slider/03.jpg",
        heading: "BRATISLAVA 14/10/2023",
        desc: "The Biggest Erasmus Festival en Bratislava con Barce"
    }, 
    {
        image: "/main-slider/04.jpg",
        heading: "BRATISLAVA 14/10/2023",
        desc: "The Biggest Erasmus Festival en Bratislava"
    }, 
    {
        image: "/main-slider/05.jpg",
        heading: "BRATISLAVA 14/10/2023",
        desc: "The Biggest Erasmus Festival en Bratislava con Seaquake"
    }, 
    {
        image: "/main-slider/06.jpg",
        heading: "KRAKOW 27/04/2023",
        desc: "The Biggest Erasmus Festival en Krakow con Alex Martini"
    }, 


]